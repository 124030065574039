export default {
    en: 'English',
    es: 'Spanish',
    ru: 'Russian',
    zh: 'Chinese',
    jp: 'Japanese',
    de: 'German',
    pt: 'Portuguese',
    fr: 'French',
    ar: 'Arabic',
    kr: 'Korean',
}
